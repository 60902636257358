import SmarthopConfirmDialog from "@smarthop/form/SmarthopConfirmDialog";
import { Typography } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";

const WarningConfirmDialog = (props) => {
	const warnings = [...(props?.warnings ? props?.warnings : [])];
	const title = "Warnings";
	const message = props?.customMessage ? props?.customMessage : "Are you sure you want to continue?";

	const onClose = () => {
		props.onClose();
	};

	const onAccept = () => {
		props.onAccept();
	};

	return (
		<SmarthopConfirmDialog
			open={props?.open}
			title={title}
			message={message}
			onClose={onClose}
			onAccept={onAccept}
			acceptMsg="Continue"
			closeMsg="No"
			maxWidth={"md"}
		>
			<div className="flex flex-col justify-center mb-14 -mt-8">
				{warnings?.map((warning, index) => {
					return (
						<div key={index} className={"flex flex-row pt-12"}>
							<Alert key="error" severity={warning.severity ?? "warning"} className="w-full items-center">
								{warning.label && <AlertTitle>{warning.label}</AlertTitle>}
								<Typography className="text-left w-full text-12 ml:text-13 leading-snug">{warning.message}</Typography>
							</Alert>
						</div>
					);
				})}
			</div>
		</SmarthopConfirmDialog>
	);
};

export default WarningConfirmDialog;
