import { Typography } from "@material-ui/core";

import ValidateMCView from "app/main/setup/components/ValidateMCView";
import { setValidateMCMethod } from "app/store/auth/registerSlice";
import { incrementDataRevision } from "app/store/tools/revisionSlice";

import { connectAxle } from "app/services/carrierServices";
import { getCarrierId, getUserTier } from "app/services/LoginService";

import SetupSidebar from "./components/SetupSidebar";
import CreateTruck from "./components/CreateTruck";
import CreateDriver from "./components/CreateDriver";
import BookMeetingView from "./components/BookMeetingView";
import SyncTrucks from "./components/SyncTrucks";
import SyncDrivers from "./components/SyncDrivers";
import store from "app/store";

const setupStepConfig = {
	path: "/setup",
	sidebarHeader: "Setup",
	renderSidebarDescription: ({ config, data }) => <SetupSidebar config={config} data={data} />,
	sidebarClass: "bg-[#E6E8EA]",
	list: [
		{
			title: "Validate MC",
			pageTitle: "Congrats! Let's Set Up Your Account",
			isAllowed: (data) => !!data?.validateMCMethod && data.onboardingInformationStatus !== "VALIDATED_LOCKED",
			isComplete: (data) => data.onboardingInformationStatus && data.onboardingInformationStatus === "VALIDATED_LOCKED",
			btnFooter: (dispatch, data, switchToStep, loading, setLoading, mobile) =>
				!data.validateMCMethod
					? [
							{
								title: "I'll do it Manually",
								style: `whitespace-nowrap px-40 md:px-72 text-14 md:text-14 font-semibold py-6 ${
									mobile ? " w-full bg-secondary text-white" : " bg-white text-black "
								}`,
								disabled: !data.hasDispatchService,
								onClick: () => {
									dispatch(setValidateMCMethod("MANUAL"));
									dispatch(incrementDataRevision({ event: "setupRevision" }));
								},
							},
							...(getUserTier() !== "TIER_STARTER"
								? [
										{
											title: "Connect with ELD",
											style:
												"whitespace-nowrap px-40 md:px-72 text-14 md:text-14 font-semibold py-6 bg-white text-white bg-secondary",
											disabled: !data.hasDispatchService,
											onClick: () => {
												const params = {
													source: "setup",
													hasDispatchService: data?.hasDispatchService,
													native: !!window?.ReactNativeWebView,
												};
												connectAxle(getCarrierId(), params).then((data) => {
													dispatch(setValidateMCMethod("ELD"));
													const reactNative = window?.ReactNativeWebView ?? false;
													if (reactNative) {
														window?.ReactNativeWebView?.postMessage(
															JSON.stringify({ type: "EXTERNAL_LINK", url: data.url })
														);
													} else {
														window.location.replace(data.url);
													}
												});
											},
										},
								  ]
								: []),
					  ]
					: [],
			clickNextToComplete: {
				onClick: ({ dispatch }) => {
					dispatch(incrementDataRevision({ event: "mcSubmitRevision" }));
				},
				submitEnabledSelector: (data) => !!data?.auth?.register?.submitMCnabled,
				alwaysResubmit: true,
			},
			render: ({ data, switchToStep, setLoading }) => {
				return <ValidateMCView data={data} switchToStep={switchToStep} setLoading={setLoading} />;
			},
		},
		{
			btnFooter: (dispatch, data, switchToStep, loading) => {
				const recordsToImport = data?.axleTrucks?.items?.filter?.((t) => !t.deleted && !t.imported) ?? [];
				const importEnabled = store.getState()?.auth?.register?.setupTruckAxleSyncSubmitEnabled;

				return [
					{
						title: "< Back",
						disabled: true,
						style: "whitespace-nowrap px-40 md:px-72 text-14 md:text-14 font-semibold py-6 bg-white text-black",
						onClick: () => {
							switchToStep(0);
						},
					},
					{
						title: recordsToImport?.length ? `Import ${recordsToImport?.length} Trucks` : "Next",
						color: "secondary",
						style: "whitespace-nowrap px-40 md:px-72 text-14 md:text-14 font-semibold py-6",
						disabled:
							loading ||
							(recordsToImport?.length > 0 && !importEnabled) ||
							(recordsToImport.length === 0 && !data.truck_complete),
						onClick: () => {
							if (recordsToImport?.length > 0) {
								dispatch(incrementDataRevision({ event: "setupTruckAxleSyncSubmitRevision" }));
							} else {
								switchToStep(2);
							}
						},
					},
				];
			},
			hidden: (data, props) => getUserTier() === "TIER_STARTER" || props?.isMobile,
			title: "Add trucks",
			pageTitle: "Add Your Trucks",
			pageSubtitle: ["Please add at least one truck. The more trucks you add now, the more time you'll save later."],
			clickNextToComplete: {
				onClick: ({ dispatch }) => {
					dispatch(incrementDataRevision({ event: "setupTruckAxleSyncSubmitRevision" }));
				},
				submitEnabledSelector: (data) => !!data?.auth?.register?.setupTruckAxleSyncSubmitEnabled,
				alwaysResubmit: true,
			},
			isComplete: (data) => data.truck_complete,
			isAllowed: (data) => data.onboardingInformationStatus === "VALIDATED_LOCKED",
			render: ({ data, switchToStep, step, loading, setLoading }) => {
				return (
					<SyncTrucks
						setupCarrierId={data?.setup_onboarding_carrier_id}
						stepData={data}
						switchToStep={switchToStep}
						step={step}
						loading={loading}
						setLoading={setLoading}
					/>
				);
			},
		},
		{
			btnFooter: (dispatch, data, switchToStep, loading) => {
				const recordsToImport = data?.axleDrivers?.items?.filter?.((d) => !d.deleted && !d.imported) ?? [];
				const importEnabled = store.getState()?.auth?.register?.setupDriverAxleSyncSubmitEnabled;
				return [
					{
						title: "< Back",
						style: "whitespace-nowrap px-40 md:px-72 text-14 md:text-14 font-semibold py-6 bg-white text-black",
						disabled: loading,
						onClick: () => {
							switchToStep(1);
						},
					},
					{
						title: recordsToImport?.length ? `Import ${recordsToImport?.length} Drivers` : "Next",
						color: "secondary",
						style: "whitespace-nowrap px-40 md:px-72 text-14 md:text-14 font-semibold py-6",
						disabled:
							loading ||
							(recordsToImport?.length > 0 && !importEnabled) ||
							(recordsToImport.length === 0 && !data.drivers_complete),
						onClick: () => {
							if (recordsToImport?.length > 0) {
								dispatch(incrementDataRevision({ event: "setupDriverAxleSyncSubmitRevision" }));
							} else {
								switchToStep(3);
							}
						},
					},
				];
			},
			hidden: (data, props) => getUserTier() === "TIER_STARTER" || props?.isMobile,
			title: "Add drivers",
			pageTitle: "Add Your Drivers",
			pageSubtitle: ["Please add at least one driver. The more drivers you add now, the more time you'll save later."],
			isComplete: (data) => data.drivers_complete,
			isAllowed: (data) => data.onboardingInformationStatus === "VALIDATED_LOCKED" && data.truck_complete,
			render: ({ data, switchToStep, step, loading, setLoading }) => {
				return (
					<SyncDrivers
						setupCarrierId={data?.setup_onboarding_carrier_id}
						stepData={data}
						step={step}
						loading={loading}
						setLoading={setLoading}
						switchToStep={switchToStep}
					/>
				);
			},
		},
		{
			hidden: (data, props) => getUserTier() !== "TIER_STARTER" && !props?.isMobile,
			title: "Add truck",
			pageTitle: "Add Your Truck",
			pageSubtitle: ["Please add at least one truck. The more trucks you add now, the more time you'll save later."],
			isComplete: (data) => data.truck_complete,
			isAllowed: (data) => data.onboardingInformationStatus === "VALIDATED_LOCKED" && !data.truck_complete,
			render: ({ data, switchToStep }) => {
				return <CreateTruck data={data} switchToStep={switchToStep} />;
			},
		},
		{
			hidden: (data, props) => getUserTier() !== "TIER_STARTER" && !props?.isMobile,
			title: "Add driver",
			pageTitle: "Add Your Driver",
			pageSubtitle: ["Please add at least one driver. The more drivers you add now, the more time you'll save later."],
			isComplete: (data) => data.drivers_complete,
			isAllowed: (data) =>
				data.onboardingInformationStatus === "VALIDATED_LOCKED" && data.truck_complete && !data.drivers_complete,
			render: ({ data, switchToStep }) => {
				return <CreateDriver data={data} switchToStep={switchToStep} />;
			},
		},
		{
			title: "Book Onboarding",
			pageTitle: "Book Your Onboarding Call",
			pageSubtitle: [
				"Before accessing the platform, you'll need to complete a 15 minute onboarding call with our team. They will show you through the basics of how to use SmartHop.",
			],
			isComplete: (data) => data.onboardingMeetingSheduled,
			isAllowed: (data) =>
				data.onboardingInformationStatus === "VALIDATED_LOCKED" && data.truck_complete && data.drivers_complete,
			render: ({ data, setLoading }) => <BookMeetingView data={data} setLoading={setLoading} />,
		},
	],
};

export default setupStepConfig;
