import PercentIcon from "@mui/icons-material/Percent";
import { getRoleLabel } from "app/services/LoginService";

const TRIP_AUTOCOMPLETE = {
	key: "load",
	type: "autocomplete",
	label: "Trip",
	autocomplete: {
		provider: "smarthop",
		url: "api/booking/trip_search",
		preloadDefault: true,
		listenEvent: "profileRevision",
		params: { options_active_only: true },
	},
	field: {
		noErrorMessage: true,
		classes: {},
		variant: undefined,
	},
};

const COST_AUTOCOMPLETE = {
	key: "costStructure",
	type: "autocomplete",
	label: "Cost Structure",
	required: false,
	autocomplete: {
		provider: "smarthop",
		url: `api/cost-structures/carriers/:carrierId`,
		preloadDefault: true,
		listenEvent: "costStructureRevision",
		params: { options_active_only: true },
	},
	field: {
		noErrorMessage: true,
		classes: {},
		variant: undefined,
	},
};

const COST_STRUCTURE_AUTOCOMPLETE = (carrierId, truckId, user, equipment) => {
	return {
		key: "costStructure",
		type: "autocomplete",
		required: false,
		autocomplete: {
			provider: "smarthop",
			url: `api/cost-structures/carriers/:carrierId`,
			preloadDefault: true,
			listenEvent: "costStructureRevision",
			params: { options_active_only: true, options_equipment: equipment },
			actionOptions: [
				{
					value: "ADD_NEW_COSTS_STRUCTURE",
					label: "New Cost Structure",
					icon: "add",
					disabled: getRoleLabel() === "dispatcher" ? true : false,
					dialog: {
						viewId: "COST_STRUCTURE",
						mode: "CREATE",
						dataIds: { carrierId: carrierId ? carrierId : null, id: false, openedFromView: true },
					},
				},
			],
			disableClearable: true,
		},
		field: {
			noErrorMessage: true,
			classes: {},
			variant: undefined,
		},
	};
};

const PRIMARY_DRIVER_AUTOCOMPLETE = {
	key: "driver",
	type: "autocomplete",
	autocomplete: {
		provider: "smarthop",
		url: "api/profile/carriers/:carrierId/drivers",
		preloadDefault: true,
		listenEvent: "profileRevision",
		params: { options_active_only: true },
		disableClearable: (val) => !val || val === "None",
		actionOptions: [
			{
				value: null,
				label: "None",
				selectable: true,
			},
			{
				value: "ADD_NEW_DRIVER",
				label: "Add New Driver",
				icon: "add",
				dialog: {
					viewId: "CARRIER_USER_EDIT_VIEW",
					mode: "CREATE",
					dataIds: { origin: "DRIVER" },
				},
			},
		],
	},
	field: {
		noErrorMessage: true,
		classes: {},
		variant: undefined,
	},
};

const CARRIER_TRAILER_AUTOCOMPLETE = {
	key: "truck_trailer",
	type: "autocomplete",
	required: true,
	autocomplete: {
		provider: "smarthop",
		url: "api/profile/carriers/:carrierId/trailers",
		preloadDefault: true,
		listenEvent: "trailerRevision",
		params: {
			options_active_only: true,
		},
		disableClearable: (val) => !val || val === "None" || val === "Power Only",
		actionOptions: [
			{
				value: "PO",
				label: "Power Only",
				metadata: {
					equipment: "PO",
				},
				selectable: true,
			},
			{
				value: "ADD_NEW_TRAILER",
				label: "Add New Trailer",
				icon: "add",
				dialog: {
					viewId: "TRAILER_VIEW",
					mode: "CREATE",
					dataIds: { mode: "CREATE" },
				},
			},
		],
	},
};

const GOAL_AUTOCOMPLETE_TRUCK = {
	key: "goals",
	type: "autocomplete",
	autocomplete: {
		provider: "smarthop",
		url: "api/goals/carriers/:carrierId/trucks/:truckId",
		preloadDefault: true,
		listenEvent: "goalsRevision",
		disableClearable: true,
		actionOptions: [
			{
				value: "CREATE_NEW_GOALS",
				label: "Create New Goals",
				icon: "add",
				dialog: {
					viewId: "CREATE_NEW_GOALS_VIEW",
					mode: "CREATE",
				},
			},
		],
	},
};

const GOAL_AUTOCOMPLETE = {
	key: "goals",
	type: "autocomplete",
	label: "Goals",
	autocomplete: {
		provider: "smarthop",
		url: "api/goals/carriers/:carrierId",
		preloadDefault: true,
		listenEvent: "goalsRevision",
		actionOptions: [
			{
				value: "CREATE_NEW_GOALS",
				label: "Create New Goals",
				icon: "add",
				dialog: {
					viewId: "CREATE_NEW_GOALS_VIEW",
					mode: "CREATE",
				},
			},
		],
	},
};

const SECONDARY_DRIVER_AUTOCOMPLETE = {
	key: "secondaryDriver",
	type: "autocomplete",
	autocomplete: {
		provider: "smarthop",
		url: "api/profile/carriers/:carrierId/drivers",
		preloadDefault: true,
		listenEvent: "profileRevision",
		params: { options_active_only: true },
		disableClearable: (val) => !val || val === "None",
		actionOptions: [
			{
				value: null,
				label: "None",
				selectable: true,
			},
			{
				value: "ADD_NEW_DRIVER",
				label: "Add New Driver",
				icon: "add",
				dialog: {
					viewId: "CARRIER_USER_EDIT_VIEW",
					mode: "CREATE",
					dataIds: { origin: "DRIVER" },
				},
			},
		],
	},
	field: {
		noErrorMessage: true,
		classes: {},
		variant: undefined,
	},
};

const modelsToSelectMapping = {
	load: TRIP_AUTOCOMPLETE,
	costStructure: COST_AUTOCOMPLETE,
	driver: PRIMARY_DRIVER_AUTOCOMPLETE,
	secondaryDriver: SECONDARY_DRIVER_AUTOCOMPLETE,
};

const DEFAULT_DRIVER_PAY = {
	payment_type: "PAY_BY_MILES",
	empty_miles_payment: 0.62,
	loaded_miles_payment: 0.62,
};

const modelsToFieldsMapping = (data) => {
	const style = {
		field: {
			noErrorMessage: true,
			classes: {},
			variant: undefined,
		},
	};

	const opts = [
		{
			value: "",
			label: "Select One",
		},
		{
			value: "PAY_BY_PERCENTAGE",
			label: "Pay by Percentage",
		},
		{
			value: "PAY_BY_MILES",
			label: "Pay by Miles",
		},
		{
			value: "FLAT_FEE",
			label: "Flat Fee",
		},
	];

	const driverSettings = (paymentType, model) => {
		const valueSelected = data && data[model] && data[model][model];

		if (!valueSelected) return [];

		return [
			{ key: "payment_type", label: "Payment Type", type: "select", options: opts, ...style },
			paymentType === "PAY_BY_MILES" && {
				key: "empty_miles_payment",
				label: "Empty Mile Rate",
				type: "number",
				...style,
			},
			paymentType === "PAY_BY_MILES" && {
				key: "loaded_miles_payment",
				label: "Loaded Mile Rate",
				type: "number",
				...style,
			},
			paymentType === "PAY_BY_PERCENTAGE" && {
				key: "gross",
				label: "Driver Pay Percentage",
				type: "percentage",
				...style,
			},
			paymentType && { key: "extra_drops", label: "Driver Pay Per Stop", type: "number", ...style },
		];
	};

	const picker = {
		picker: {
			disablePast: false,
			disableFuture: false,
			notClearable: false,
			type: "datetime",
			classes: {},
		},
	};

	return {
		load: [
			{ key: "emptymiles", label: "Empty Miles", type: "number", ...style },
			{ key: "miles", label: "Loaded Miles", type: "number", ...style },
			{ key: "rate", label: "Load Rate", type: "number", ...style },
			{ key: "stops", label: "Extra Stops", type: "number", ...style },
			{ key: "pickup_date", label: "Pickup Date", type: "picker", ...style, ...picker },
			{ key: "delivery_date", label: "Delivery Date", type: "picker", ...style, ...picker },
		],
		costStructure: [
			{ key: "percentageCost", label: "Percentage Cost", type: "percentage", ...style },
			{ key: "fixedMonthlyCosts", label: "Fixed Monthly Costs", type: "number", ...style },
			{ key: "period", label: "Monthly Utilization", type: "number", ...style },
			{ key: "costPerMile", label: "Cost Per Mile", type: "number", ...style },
			{ key: "avgMilesPerDay", label: "Avg Miles Per Day", type: "number", ...style },
		],
		driver: driverSettings(data?.driver?.payment_type, "driver"),
		secondaryDriver: driverSettings(data?.secondaryDriver?.payment_type, "secondaryDriver"),
	};
};

const printCurrency = (num, overrideColor = null) => {
	let parsed = parseFloat(num);
	const color = parsed < 0 ? "text-red-500" : "text-green-700";
	return (
		<div className={`${overrideColor ?? color}`}>
			$ {parsed.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
		</div>
	);
};

const getUnaccountedPercent = (data) => {
	const secondary = data?.secondaryDriver ?? data?.driver_secondary;
	if (data?.driver?.driver_type !== "OWNER_OPERATOR" && secondary?.driver_type !== "OWNER_OPERATOR") return 0;

	const driver1Cut = data?.driver?.driver_type === "OWNER_OPERATOR" ? data?.driver?.gross ?? 0 : 0;
	const driver2Cut = secondary?.driver_type === "OWNER_OPERATOR" ? secondary?.gross ?? 0 : 0;
	const gmvCut = data?.costStructure?.percentageCost ?? 0;
	const totalcuts = gmvCut + driver1Cut + driver2Cut;
	const leftover = 100 - totalcuts;

	return leftover;
};

const parseNormalField = (fieldPath, data, options) => {
	const [model, field] = fieldPath.split(".");
	let val = data[model] ? data[model][field] ?? 0 : 0;

	if (options?.formatting) {
		if (options.formatting === "__DATE__") {
			val = new Date(val).valueOf();
		} else if (options.formatting === "__PERCENT__") {
			val = val / 100;
		}
	}

	if (options?.min && val < options.min) {
		val = options.min;
	}

	if (options?.default && !val) {
		val = options.default;
	}

	return ` ${val} `;
};

const calculateCost = (expenses, data, fromCalculator = false) => {
	let expression = "";

	if (!expenses) return 0;

	const expensesToUse = parseEquation(expenses, data);
	if (expensesToUse === false) return 0;

	expensesToUse.forEach((expense) => {
		if (Array.isArray(expense[0])) {
			expression += "(" + calculateCost(expense, data, fromCalculator) + ")";
		} else {
			// eslint-disable-next-line
			const [label, fieldPath, fieldType, options] = expense;
			if (fieldType === "__OP__" || fieldType === "__CONSTANT__") {
				expression += ` ${fieldPath} `;
			} else if (fieldType === "__DISPLAY_RESULT__") {
				let res = calculateCost(fieldPath, data, fromCalculator);
				if (options?.min && res < options.min) {
					res = options.min;
				}
				if (options?.decimals) {
					res = parseFloat(parseFloat(res ?? 0).toFixed(options.decimals));
				}

				if (options?.formatting && options.formatting === "__PERCENT__") {
					res = res / 100;
				}

				expression += "(" + res + ")";
			} else if (fieldType === "__CONDITIONAL__") {
				const conditionsMet = options.condition.reduce((acc, cond) => {
					const [model, field] = cond.field.split(".");
					return acc && data[model] && data[model][field] === cond.value;
				}, true);
				const res = conditionsMet ? parseNormalField(fieldPath, data, options) : 0;
				expression += "(" + res + ")";
			} else if (fieldType === "__SPECIAL_FIELD__") {
				if (fieldPath === "__NUMBER_OF_DRIVERS__") {
					const num = fromCalculator
						? data["secondaryDriver"] && data["secondaryDriver"]["secondaryDriver"]
							? 2
							: 1
						: data["secondaryDriver"]
						? 2
						: 1;

					expression += ` ${num} `;
				}
			} else if (fieldType === "__CALLBACK__") {
				const res = fieldPath(data);
				expression += ` ${res} `;
			} else {
				expression += parseNormalField(fieldPath, data, options);
			}
		}
	});

	try {
		// eslint-disable-next-line
		const result = eval(expression);
		return isNaN(result) ? 0 : result;
	} catch (e) {
		return 0;
	}
};

const getActiveExpenses = (d) => {
	const data = getProfitDataToUse(d);
	const expenses = getTripExpenses(data);
	return expenses.filter((expense) => {
		if (expense.hide) {
			const shouldHide = expense.hide.reduce((acc, condition) => {
				const [model, field] = condition.field.split(".");
				return acc && data[model] && data[model][field] === condition.value;
			}, true);
			return !shouldHide;
		}

		if (expense.showOR) {
			const shouldShow = expense.showOR.reduce((acc, condition) => {
				const [model, field] = condition.field.split(".");
				return acc || (data[model] && data[model][field] === condition.value);
			}, false);
			return shouldShow;
		}

		return true;
	});
};

const parseEquation = (equation, data) => {
	let equationToUse = equation;

	if (equationToUse?.switch && equationToUse?.field) {
		const [model, field] = equationToUse.field;
		const val = data[model] && data[model][field];
		equationToUse = val && equationToUse.switch[val] ? equationToUse.switch[val] : equationToUse.switch.default;
	}

	if (equationToUse?.hasData && equationToUse?.yes && equationToUse?.no) {
		const hasData = equationToUse.hasData.reduce((acc, val) => {
			const [model, field] = val.split(".");
			return data[model] && data[model][field] && acc;
		}, true);
		equationToUse = hasData ? equationToUse?.yes : equationToUse?.no;
	}

	return equationToUse;
};

const costStructureFields = {
	name_cost_structure: {
		key: "name_cost_structure",
		type: "text",
		label: "Name",
		required: true,
	},
	percentageCost: {
		key: "percentageCost",
		type: "percentage",
		label: "GMV Percentage Cost",
		description: "Dispatcher fee, Factoring fee, or other fees taken as a percentage of revenue.",
		defaultValue: 2,
		required: true,
	},
	costPerMile: {
		key: "costPerMile",
		type: "currency",
		label: "Maintenance Cost Per Mile",
		description: "Estimated Maintenance cost per mile (Fuel and Driver Pay will be added dynamically).",
		defaultValue: 0.09,
		required: true,
	},
	fixedMonthlyCosts: {
		key: "fixedMonthlyCosts",
		type: "currency",
		label: "Monthly Expenses",
		description: "Truck payment, Insurance, Trailer Maintenance, etc",
		defaultValue: 5600,
		required: true,
	},
	period: {
		key: "period",
		type: "number",
		label: "Monthly Utilization",
		description: "Truck monthly utilization. Used to determine how much monthly expenses to allocate per day",
		defaultValue: 23,
		required: true,
	},
	avgMilesPerDay: {
		key: "avgMilesPerDay",
		type: "number",
		label: "Avg Miles Per Day",
		description: "Used to determine how much monthly expenses to allocate per day",
		defaultValue: 550,
		required: true,
	},
};

const costSectionsFields = {
	"Driver Pay": {
		icon: "person",
		description: "Can be paid per mile or a percentage. Setting is on driver profile.",
		short: "Driver",
	},
	"Secondary Driver Pay": {
		icon: "people_outline",
		description: "Can be paid per mile or a percentage. Setting is on driver profile.",
		short: "Driver 2",
	},
	"Allocated Monthly Expenses": {
		icon: "today",
		description:
			"A portion of the fixed monthly expenses (like Truck payments, Insurance, Trailer Maintenance) allocated for this trip.",
		short: "Fixed",
	},
	"GMV Percentage Cost": {
		icon: (fontSize) => <PercentIcon className="mr-4" style={{ fontSize }} />,
		description: costStructureFields.percentageCost.description,
		short: "GMV",
	},
	"Per Mile Expenses": {
		icon: "drive_eta",
		description: costStructureFields.costPerMile.description,
		short: "Maintenance",
	},
	"Carrier Cost": {
		icon: "business",
		description: "If driver is a owner operator, the carrier gets whatever GMV% is left over",
		short: "Carrier Cut",
	},
	"Fuel Cost": {
		icon: "local_gas_station",
		short: "Fuel",
	},
};

const getProfitDataToUse = (d) => {
	const dataToUse = { ...d };
	if (dataToUse.driver_secondary && !dataToUse.secondaryDriver) {
		dataToUse.secondaryDriver = dataToUse.driver_secondary;
	}

	return dataToUse;
};

const PROFIT_WARNINGS = {
	GMV_OVERFLOW: "GMV_OVERFLOW",
};

const getProfitWarnings = (data) => {
	let warnings = [];
	const overflow = getUnaccountedPercent(data);
	if (overflow !== 0) {
		warnings.push(PROFIT_WARNINGS.GMV_OVERFLOW);
	}

	return warnings;
};

const calculateProfit = (d, includeFixed = true) => {
	const data = getProfitDataToUse(d);
	let totalExpenses = 0;
	let variableExpenses = 0;
	let fixedExpenses = 0;
	const activeExpenses = getActiveExpenses(data);
	const expenseList = {};
	for (let i = 0; i < activeExpenses.length; i++) {
		const expense = activeExpenses[i];
		const val = calculateCost(expense.equation, data, false);

		if (val > 0) {
			expenseList[expense.label] = val;
		}

		if (includeFixed || expense.label !== "Allocated Monthly Expenses") {
			totalExpenses += val;
		}

		if (expense.label === "Allocated Monthly Expenses") {
			fixedExpenses += val;
		} else {
			variableExpenses += val;
		}
	}

	const revenue = +data?.load?.rate ?? 0;
	const totalAdditions = data?.load?.logistics_costs?.reduce((acc, addDeduction) => {
		const value = addDeduction?.direction === "CREDIT" ? addDeduction?.amount : 0;
		return acc + value;
	}, 0);
	const totalDeductions = data?.load?.logistics_costs?.reduce((acc, addDeduction) => {
		const value = addDeduction?.direction === "DEBIT" ? addDeduction?.amount : 0;
		return acc + value;
	}, 0);
	const profit = revenue + totalAdditions - totalDeductions - totalExpenses;
	const duration = calculateCost(CALC_TRIP_DURATION().equation, data);
	const totalMiles = (data?.load?.miles ?? 0) + (data?.load?.emptymiles ?? 0);
	const rpm = revenue / totalMiles;
	const ppd = profit / duration;
	const warnings = getProfitWarnings(data);

	return {
		totalMiles,
		ppd,
		rpm,
		duration,
		totalExpenses,
		profit: isNaN(profit) ? profit : parseFloat(parseFloat(profit).toFixed(2)),
		revenue,
		totalAdditions,
		totalDeductions,
		fixedExpenses,
		variableExpenses,
		expenses: activeExpenses,
		expenseList,
		warnings,
	};
};

// equations
const CALC_DRIVER_PAY = (model) => {
	const DRIVER_PAY_PERCENTAGE_EQUATION = () => {
		return [
			["Driver Pay Percentage", `${model}.gross`, "", { formatting: "__PERCENT__" }],
			["", "*", "__OP__"],
			["Load Rate", "load.rate", "", { formatting: "__USD__" }],
			["", "+", "__OP__"],
			[
				["Pay per Stop", `${model}.extra_drops`, "", { formatting: "__USD__" }],
				["", "*", "__OP__"],
				["Extra Stops", "load.stops"],
			],
		];
	};

	const DRIVER_PAY_MILES_EQUATION = () => {
		return [
			[
				[
					[
						"Empty Mile Rate",
						`${model}.empty_miles_payment`,
						"",
						{ formatting: "__USD__", default: DEFAULT_DRIVER_PAY.empty_miles_payment },
					],
					["", "*", "__OP__"],
					["Empty Miles", "load.emptymiles", "", { formatting: "__MILES__" }],
					["", "+", "__OP__"],
					[
						"Loaded Mile Rate",
						`${model}.loaded_miles_payment`,
						"",
						{ formatting: "__USD__", default: DEFAULT_DRIVER_PAY.loaded_miles_payment },
					],
					["", "*", "__OP__"],
					["Loaded Miles", "load.miles", "", { formatting: "__MILES__" }],
				],
				["", "/", "__OP__"],
				["Number of Drivers", "__NUMBER_OF_DRIVERS__", "__SPECIAL_FIELD__"],
			],
			["", "+", "__OP__"],
			[
				["Pay per Stop", `${model}.extra_drops`, "", { formatting: "__USD__" }],
				["", "*", "__OP__"],
				["Extra Stops", "load.stops"],
			],
		];
	};

	const DRIVER_PAY_FLAT_FEE = () => {
		return [
			["Flat Fee", `${model}.flat_fee_amount`, "", { formatting: "__USD__" }],
			["", "+", "__OP__"],
			[
				["Pay per Stop", `${model}.extra_drops`, "", { formatting: "__USD__" }],
				["", "*", "__OP__"],
				["Extra Stops", "load.stops"],
			],
		];
	};

	return {
		hide: [{ field: `${model}.driver_type`, value: "OWNER_OPERATOR" }],
		label: `${model === "driver" ? "Driver" : "Secondary Driver"} Pay`,
		equation: {
			field: [model, "payment_type"],
			switch: {
				PAY_BY_PERCENTAGE: DRIVER_PAY_PERCENTAGE_EQUATION(),
				PAY_BY_MILES: DRIVER_PAY_MILES_EQUATION(),
				FLAT_FEE: DRIVER_PAY_FLAT_FEE(),
				default: DRIVER_PAY_MILES_EQUATION(),
			},
		},
	};
};
const CALC_FIXED_COST = () => {
	return {
		label: "Allocated Monthly Expenses",
		equation: {
			hasData: ["load.delivery_date", "load.pickup_date"],
			yes: [
				[
					["Fixed Costs", "costStructure.fixedMonthlyCosts", "", { formatting: "__USD__" }],
					["", "/", "__OP__"],
					["Monthly Utilization", "costStructure.period", "", { formatting: "__DAYS__" }],
				],
				["", "*", "__OP__"],
				[
					"Trip Duration",
					[
						[
							["Delivery Date", "load.delivery_date", "", { formatting: "__DATE__" }],
							["", "-", "__OP__"],
							["Pickup Date", "load.pickup_date", "", { formatting: "__DATE__" }],
						],
						["", "/", "__OP__"],
						["Milliseconds in a Day", 86400000, "__CONSTANT__"],
					],
					"__DISPLAY_RESULT__",
					{ min: 0.5, formatting: "__DAYS__" },
				],
			],
			no: [
				[
					["Fixed Costs", "costStructure.fixedMonthlyCosts", "", { formatting: "__USD__" }],
					["", "/", "__OP__"],
					["Monthly Utilization", "costStructure.period", "", { formatting: "__DAYS__" }],
				],
				["", "*", "__OP__"],
				[
					"Trip Duration",
					[
						[
							["Loaded Miles", "load.miles", "", { formatting: "__MILES__" }],
							["", "+", "__OP__"],
							["Empty Miles", "load.emptymiles", "", { formatting: "__MILES__" }],
						],
						["", "/", "__OP__"],
						["Avg Per Day", "costStructure.avgMilesPerDay", "", { formatting: "__MILES__" }],
					],
					"__DISPLAY_RESULT__",
					{ min: 0.5, formatting: "__DAYS__" },
				],
			],
		},
	};
};
const CALC_PERCENTAGE_COST = (data) => {
	const getPercentValue = (d, percent, type) => {
		const overflow = getUnaccountedPercent(d);
		if (overflow >= 0) {
			return type === "label" ? percent : percent / 100;
		}

		return type === "label"
			? {
					value: percent + overflow,
					warning: `Adjusted from ${percent}% to ${percent + overflow}% due to Cost Structure configuration error`,
			  }
			: (percent + overflow) / 100;
	};

	if (data?.costStructure?.useHelper) {
		return {
			label: "GMV Percentage Cost",
			equation: [
				[
					[
						"Factoring Fee",
						(d, type) => {
							const percent = d?.costStructure?.meta?.gmvHelper?.fees ?? 0;
							return type === "label" ? percent : percent / 100;
						},
						"__CALLBACK__",
						{ formatting: "__PERCENT__" },
					],
					["", "+", "__OP__"],
					[
						"Dispatching + Other Fees",
						(d, type) => {
							const percent = d?.costStructure?.meta?.gmvHelper?.otherFees ?? 0;
							return getPercentValue(d, percent, type);
						},
						"__CALLBACK__",
						{ formatting: "__PERCENT__" },
					],
				],
				["", "*", "__OP__"],
				["Load Rate", "load.rate", "", { formatting: "__USD__" }],
			],
		};
	}

	return {
		label: "GMV Percentage Cost",
		equation: [
			[
				"Percentage Cost",
				(d, type) => {
					const percent = d?.costStructure?.percentageCost ?? 0;
					return getPercentValue(d, percent, type);
				},
				"__CALLBACK__",
				{ formatting: "__PERCENT__" },
			],
			["", "*", "__OP__"],
			["Load Rate", "load.rate", "", { formatting: "__USD__" }],
		],
	};
};
const CALC_COST_PER_MILE = () => {
	return {
		label: "Per Mile Expenses",
		equation: [
			[
				["Loaded Miles", "load.miles", "", { formatting: "__MILES__" }],
				["", "+", "__OP__"],
				["Empty Miles", "load.emptymiles", "", { formatting: "__MILES__" }],
			],
			["", "*", "__OP__"],
			["Cost Per Mile", "costStructure.costPerMile", "", { formatting: "__USD__" }],
		],
	};
};
const CALC_TRIP_DURATION = () => {
	return {
		label: "Trip Duration",
		equation: {
			hasData: ["load.delivery_date", "load.pickup_date"],
			yes: [
				[
					"Trip Duration",
					[
						[
							["Delivery Date", "load.delivery_date", "", { formatting: "__DATE__" }],
							["", "-", "__OP__"],
							["Pickup Date", "load.pickup_date", "", { formatting: "__DATE__" }],
						],
						["", "/", "__OP__"],
						["Milliseconds in a Day", 86400000, "__CONSTANT__"],
					],
					"__DISPLAY_RESULT__",
					{ min: 0.5, formatting: "__DAYS__", decimals: 2 },
				],
			],
			no: [
				[
					"Trip Duration",
					[
						[
							["Loaded Miles", "load.miles", "", { formatting: "__MILES__" }],
							["", "+", "__OP__"],
							["Empty Miles", "load.emptymiles", "", { formatting: "__MILES__" }],
						],
						["", "/", "__OP__"],
						["Avg Per Day", "costStructure.avgMilesPerDay", "", { formatting: "__MILES__" }],
					],
					"__DISPLAY_RESULT__",
					{ min: 0.5, formatting: "__DAYS__", decimals: 2 },
				],
			],
		},
	};
};
const CALC_FUEL_COST = () => {
	return {
		label: "Fuel Cost",
		equation: [
			[
				[
					["Loaded Miles", "load.miles", "", { formatting: "__MILES__" }],
					["", "+", "__OP__"],
					["Empty Miles", "load.emptymiles", "", { formatting: "__MILES__" }],
				],
				["", "/", "__OP__"],
				["MPG", "truck.miles_per_gallon", "", { default: 5 }],
			],
			["", "*", "__OP__"],
			[
				"Avg Fuel Cost/Gallon",
				"load.fuel",
				"",
				{ formatting: "__USD__", default: localStorage.getItem("AVG_FUEL_COST") },
			],
		],
	};
};

const getTripExpenses = (data) => {
	const fixedCost = CALC_FIXED_COST();
	const driverCost = CALC_DRIVER_PAY("driver");
	const secondaryDriverCost = CALC_DRIVER_PAY("secondaryDriver");
	const travelCost = CALC_COST_PER_MILE();
	const percentageCost = CALC_PERCENTAGE_COST(data);
	const fuelCost = CALC_FUEL_COST();

	return [
		percentageCost,
		fixedCost,
		travelCost,
		driverCost,
		...(data["secondaryDriver"] ? [secondaryDriverCost] : []),
		fuelCost,
	];
};

export {
	getProfitDataToUse,
	CALC_TRIP_DURATION,
	calculateProfit,
	costSectionsFields,
	costStructureFields,
	TRIP_AUTOCOMPLETE,
	COST_AUTOCOMPLETE,
	GOAL_AUTOCOMPLETE,
	GOAL_AUTOCOMPLETE_TRUCK,
	PRIMARY_DRIVER_AUTOCOMPLETE,
	SECONDARY_DRIVER_AUTOCOMPLETE,
	CARRIER_TRAILER_AUTOCOMPLETE,
	modelsToSelectMapping,
	modelsToFieldsMapping,
	printCurrency,
	parseEquation,
	calculateCost,
	getActiveExpenses,
	COST_STRUCTURE_AUTOCOMPLETE,
	getProfitWarnings,
	PROFIT_WARNINGS,
	getUnaccountedPercent,
};
