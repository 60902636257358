import { useState, useEffect, useRef } from "react";
import { useSnackbar } from "notistack";
import { showSnackbar } from "app/main/utils/snackbarUtil";
import { useDispatch, useSelector } from "react-redux";

// UI
import SmarthopFormView from "@smarthop/form/SmarthopFormView";
import { Typography, Button, Icon } from "@material-ui/core";
import { MAIN_FIELDS } from "@smarthop/form/configs/carrierCompanyInformationForm";
import SetupIntro from "./SetupIntro";

// Services
import { getCarrierId, getUserId } from "app/services/LoginService";
import {
	getCarrierDOTInformation,
	updateCarrierInformation,
	verifyCarrierDOTValidationCode,
	sendCarrierDOTEmailCode,
	createUserLinkedAccount,
} from "app/services/carrierServices";

// Storage
import { incrementDataRevision } from "app/store/tools/revisionSlice";
import { fetchActions } from "app/store/actions/actionsUserSlice";
import { fetchCredentialsStatus } from "app/store/search/credentialsSlice";
import { setSubmitMCnabled } from "app/store/auth/registerSlice";
import { Alert } from "@material-ui/lab";

const ValidateMCView = ({ config, data, onDone, dataIds, setTitle, setSize, switchToStep, setLoading }) => {
	useEffect(() => {
		setTitle?.("Company Information");
		setSize?.("max-w-lg");
		// eslint-disable-next-line
	}, []);

	const snackbar = useSnackbar();
	const dispatch = useDispatch();

	// Use default user Id if not provided
	const userId = getUserId();
	const mainCarrierId = dataIds?.carrierId ?? getCarrierId();
	const validationFlowOnly = dataIds?.state === "VALIDATE";
	const hasDispatchService = data.hasDispatchService === "YES";

	// User information
	const user = useSelector(({ auth }) => auth.user);
	const mcSubmitRevision = useSelector(({ tools }) => tools.revision["mcSubmitRevision"]);
	const mcSubmitRevisionRef = useRef(mcSubmitRevision);

	// Values
	const [mc, setMC] = useState(null);
	const [dot, setDOT] = useState(null);
	const [carrierId, setCarrierId] = useState(mainCarrierId);
	const [carrierData, setCarrierData] = useState(null);
	const [uneditedDotSync, setUneditedDotSync] = useState(false);
	const [validationType, setValidationType] = useState(null);
	const [validationCode, setValidationCode] = useState(null);
	const [validationError, setValidationError] = useState(null);
	const [validationRevision, setValidationRevision] = useState(0);
	const [parentValidated, setParentValidated] = useState(false);
	const [testCarrier, setTestCarrier] = useState(false);
	const [carrierTypeCompany, setCarrierType] = useState(null);
	const [carrierRepresentative, setCarrierRepresentative] = useState(null);
	const [parent, setParent] = useState(null);
	const isDispatcher = carrierTypeCompany === "DISPATCH_SERVICE";

	const manualEditFormFormsRef = useRef(0);

	// Forcefull override of the validation of the email and phone in case
	// if carrier is a test carrier to avoid sending emails to carriers we use for tests
	const overrideEmailValue = testCarrier ? user.email ?? "support@smarthop.co" : null;
	const overridePhoneValue = testCarrier ? user.phone ?? "+10000000000" : null;

	// States
	const [state, setState] = useState("LOADING");
	const [inProgress, setInProgress] = useState(false);

	useEffect(() => {
		if (mcSubmitRevision && mcSubmitRevision !== mcSubmitRevisionRef.current) {
			mcSubmitRevisionRef.current = mcSubmitRevision;
			if ((state === "LOADED" || state === "SYNC_FAILURE") && (mc || dot)) {
				hasDispatchService ? createAccount() : !isDispatcher ? syncDOTInfo(carrierId) : continueManually();
			} else if (state === "VALIDATE_SENT" && validationCode) {
				verifyValidationCode();
			}
		}
		// eslint-disable-next-line
	}, [mcSubmitRevision]);

	useEffect(() => {
		if ((state === "LOADED" || state === "SYNC_FAILURE") && (mc || dot)) {
			dispatch(setSubmitMCnabled(true));
		} else if (state === "VALIDATE_SENT" && validationCode) {
			dispatch(setSubmitMCnabled(true));
		} else {
			dispatch(setSubmitMCnabled(false));
		}
	}, [dispatch, mc, dot, validationCode, state]);

	useEffect(() => {
		let clear = false;
		if (state !== "LOADING") return;

		(async () => {
			try {
				if (clear) return;

				setCarrierData(data);
				setMC(data.carrier_mc_number);
				setDOT(data.carrier_dot_number);
				setCarrierType(data.typeCompany);
				setTestCarrier(!!user.isTestCarrier);
				setCarrierRepresentative(data.carrierRepresentative);
				setParent(data?.parent);

				// If opened via validation flow, we need to go to validation process right away
				if (validationFlowOnly) {
					setValidationType(data.carrier_email ? "EMAIL" : data.carrier_phone ? "PHONE" : "NO_SUPPORTED");
					setUneditedDotSync(data.onboardingDotInfoUnedited);
					setParentValidated(data.onboardingParentValidated);
					setState("VALIDATE");
				} else {
					setState("LOADED");
				}
			} catch (e) {
				showSnackbar(snackbar, e.errors?.[0]?.message ?? "Opps, failed to load data...", "error");
			}
		})();

		return () => {
			clear = true;
		};
		// eslint-disable-next-line
	}, []);

	const createAccount = async () => {
		if (inProgress) return;
		setInProgress(true);
		setLoading?.(true);

		try {
			let setupCarrierId = null;
			if (data.subaccount) {
				setupCarrierId = data.subaccount._id;
			} else {
				const data = { carrier_mc_number: mc, carrier_dot_number: dot };
				const response = await createUserLinkedAccount(userId, data);
				setupCarrierId = response.carrierId;
			}

			setCarrierId(setupCarrierId);
			syncDOTInfo(setupCarrierId);
		} catch (e) {
			setInProgress(false);
			setLoading?.(false);
			showSnackbar(snackbar, e.errors?.[0]?.message ?? "Opps, failed to load data...", "error");
		}
	};

	const syncDOTInfo = async (carrierIdAux) => {
		if (inProgress) return;
		setInProgress(true);
		setLoading?.(true);

		try {
			const dotSyncData = await getCarrierDOTInformation(carrierIdAux, mc, dot);
			setInProgress(false);
			setLoading?.(false);
			setState(dotSyncData.status === "SUCCESS" ? "SYNC_SUCCESS" : "SYNC_FAILURE");

			if (dotSyncData.status === "SUCCESS") {
				setCarrierData({
					...dotSyncData.data,
					typeCompany: carrierTypeCompany,
					parent: parent,
					carrierRepresentative: carrierRepresentative,
				});
				setMC(dotSyncData.data.carrier_mc_number);
				setDOT(dotSyncData.data.carrier_dot_number);
				setUneditedDotSync(true);
				setParentValidated(dotSyncData.data.onboardingParentValidated);
				setValidationType(
					dotSyncData.data?.carrier_email ? "EMAIL" : dotSyncData.data?.carrier_phone ? "PHONE" : "NO_SUPPORTED"
				);

				saveCompanyInfo(carrierIdAux, "VALIDATE", {
					...dotSyncData.data,
					typeCompany: carrierTypeCompany,
					parent: parent,
					carrierRepresentative: carrierRepresentative,
				});
			}
		} catch (e) {
			setInProgress(false);
			setLoading?.(false);
			showSnackbar(snackbar, e.errors?.[0]?.message ?? "Opps, failed to load data...", "error");
		}
	};

	const continueManually = () => {
		manualEditFormFormsRef.current += 1;
		setState("EDIT_MANUALLY");
	};

	const saveCompanyInfo = async (carrierIdAux, toState, carrierData) => {
		if (inProgress) return;
		setInProgress(true);
		setLoading?.(true);

		if (!parentValidated) delete carrierData.onboardingParentValidated;
		try {
			await updateCarrierInformation(carrierIdAux, { ...carrierData, uneditedDotSync: true, formUpdate: true });
			setInProgress(false);
			setLoading?.(false);

			if (toState) {
				dispatch(incrementDataRevision({ event: "profileRevision" }));
				dispatch(incrementDataRevision({ event: "onboardingRevision" }));
				setState(toState);
			} else {
				showSnackbar(snackbar, "Done!", "success");
				dispatch(incrementDataRevision({ event: "profileRevision" }));
				dispatch(incrementDataRevision({ event: "onboardingRevision" }));
				onDone?.();
			}
			setValidationType("EMAIL");
			sendValidationCode(carrierIdAux);
			dispatch(setSubmitMCnabled(false));
		} catch (e) {
			setInProgress(false);
			setLoading?.(false);
			showSnackbar(snackbar, e.errors?.[0]?.message ?? "Opps, failed to load data...", "error");
		}
	};

	const sendValidationCode = async (carrierIdAux) => {
		if (inProgress) return;
		setInProgress(true);
		setLoading?.(true);

		try {
			let result;
			// if (validationType === "PHONE") {
			// 	result = await sendCarrierDOTPhoneCode(carrierId, { overridePhoneValue });
			// } else if (validationType === "EMAIL") {
			result = await sendCarrierDOTEmailCode(carrierIdAux, { overrideEmailValue });
			// }
			setValidationRevision((validationRevision ?? 0) + 1);
			setValidationCode(null);
			setInProgress(false);
			setLoading?.(false);
			if (result.message) {
				// Inernal message indicated to which email or phone code was send,
				// should be user email when overriden for test carriers
				showSnackbar(snackbar, result.message, "info");
			}
			setState("VALIDATE_SENT");
		} catch (e) {
			console.log(e);
			setInProgress(false);
			setLoading?.(false);
			showSnackbar(snackbar, e.errors?.[0]?.message ?? "Opps, failed to load data...", "error");
		}
	};

	const verifyValidationCode = async () => {
		if (inProgress) return;
		if (!validationCode) return;
		setInProgress(true);
		setLoading?.(true);

		try {
			const parentCarrierId = hasDispatchService ? mainCarrierId : null;
			await verifyCarrierDOTValidationCode(carrierId, { code: validationCode, parentCarrierId });
			dispatch(incrementDataRevision({ event: "setupRevision" }));
			setInProgress(false);
			setLoading?.(false);
			dispatch(fetchActions({ carrierId: mainCarrierId }));
			dispatch(fetchCredentialsStatus({ carrierId: mainCarrierId }));
			// switchToStep(2);
		} catch (e) {
			setInProgress(false);
			setLoading?.(false);
			if (e.errors?.[0]?.type === "code") {
				setValidationError(e.errors?.[0]?.message);
			} else {
				showSnackbar(snackbar, e.errors?.[0]?.message ?? "Opps, failed to load data...", "error");
			}
		}
	};

	return (
		<div
			className={
				"w-full flex flex-col items-center justify-center pb-12 pt-4 " +
				(inProgress ? " opacity-50 pointer-events-none " : "")
			}
		>
			{!data?.validateMCMethod ? (
				<SetupIntro data={data} switchToStep={switchToStep} />
			) : state === "VALIDATE_SENT" ? (
				<>
					<div className="flex flex-row w-full items-center pr-20 mb-10">
						<div className="flex flex-col w-full mb-12 mt-4">
							<Typography color="textPrimary" className="flex w-full text-13 font-normal self-start">
								{validationType === "ANY"
									? "Please provide verification code you received"
									: `We sent a validation code to the ${
											validationType === "PHONE" ? "phone" : "email " + overrideEmailValue
									  } associated with the MC or DOT number at the FMSCA. 
							Please enter the validation code to confirm ${carrierData.carrier_company_name} ownership`}
							</Typography>
							<Typography color="textPrimary" className="flex w-full text-13 text-grey font-normal text-start">
								{validationType === "PHONE"
									? "Code will be valid for 10 minutes only"
									: validationType === "EMAIL"
									? ""
									: null}
							</Typography>
						</div>
					</div>
					<SmarthopFormView
						key={"validate_code_data_" + validationRevision}
						mode="EDIT"
						data={{ code: validationCode }}
						content={{
							form: { noErrorMessage: true },
							items: [{ key: "code", type: "text", label: "Validation Code", required: true }],
						}}
						errors={validationError ? [{ type: "code", message: validationError }] : null}
						noInitValidation={true}
						trackChangedFields={["ALL"]}
						onChangeCommitted={(model) => {
							setValidationCode(model.code);
						}}
					/>
					{/* <div className="flex flex-col w-full items-center justify-center mt-2">
						<Button
							key="confirm_code"
							color="secondary"
							variant="contained"
							className="flex-1 w-full px-24"
							disabled={!validationCode}
							onClick={() => verifyValidationCode()}
						>
							Confirm
						</Button>
					</div> */}
				</>
			) : state === "VALIDATE" ? (
				carrierData.onboardingInformationStatus === "VALIDATED" ||
				carrierData.onboardingInformationStatus === "VALIDATED_LOCKED" ? (
					// Already validated
					<>
						<div className="flex flex-row w-full items-center pl-10 pr-20 justify-center">
							<Icon className="text-36 text-green mr-6 mb-6">check_circle</Icon>
							<Typography color="textPrimary" className="flex-1 text-13 mb-14 mt-10 font-normal text-left">
								Company information has already been validated
							</Typography>
						</div>
						<Button
							key="back_validate"
							color="primary"
							variant="contained"
							className="flex-1 w-full px-24 mt-10"
							onClick={() => {
								if (validationFlowOnly) {
									onDone?.();
								} else {
									setState("SYNC_SUCCESS");
								}
							}}
						>
							{validationFlowOnly ? "Close" : "Back"}
						</Button>
					</>
				) : validationType === "NOT_SUPPORTED" || (user?.role !== "ADMIN" && !uneditedDotSync) ? (
					// If data has not been synced from DOT, validation would not be supported
					// (admin can validate even data that was not synced via DOT)
					<>
						<div className="flex flex-row w-full items-center pl-10 pr-20 justify-center">
							<Icon className="text-36 text-grey mr-6 mb-6">warning</Icon>
							<Typography color="textPrimary" className="flex-1 text-13 mb-14 mt-10 font-normal">
								Unfortunately, we can not validate your account information at this moment.
							</Typography>
						</div>
						<Button
							key="back_validate"
							color="primary"
							variant="contained"
							className="flex-1 w-full px-24 mt-10"
							onClick={() => {
								if (validationFlowOnly) {
									onDone?.();
								} else {
									setState("SYNC_SUCCESS");
								}
							}}
						>
							{validationFlowOnly ? "Close" : "Back"}
						</Button>
					</>
				) : (
					<div className="flex flex-row items-center justify-center my-40">
						<Typography color="textPrimary" className="text-14 mb-20 mt-16 font-normal">
							Syncing...
						</Typography>
					</div>
				)
			) : state === "EDIT_MANUALLY" ? (
				<>
					<div className="flex flex-row w-full items-center pl-10 pr-20 mb-20 justify-center border-b-1">
						<Icon className="text-36 text-blue mr-6">create</Icon>
						<Typography color="textPrimary" className="flex-1 text-13 mb-14 mt-10 font-normal">
							Please provide your company information. Our team will reach you out to activate your account and validate
							correctness of the provided information
						</Typography>
					</div>
					<SmarthopFormView
						key={"edit_data_manually_" + manualEditFormFormsRef.current}
						mode="EDIT"
						data={carrierData}
						content={{ items: MAIN_FIELDS(false, false, carrierData?.parent) }}
						noInitValidation={true}
						trackChangedFields={["ALL"]}
						onChangeCommitted={(model) => {
							setCarrierData({ ...model, parent });
							console.log("setUneditedDotSync false");
							setUneditedDotSync(false);
							setParentValidated(false);
						}}
					/>
					<div className={"flex flex-col w-full items-center justify-center -mt-16"}>
						<Button
							key="save_edited"
							color="secondary"
							variant="contained"
							className="flex-1 w-full px-24"
							onClick={() => saveCompanyInfo()}
						>
							Save
						</Button>
					</div>
				</>
			) : state === "SYNC_SUCCESS" ? (
				<div className="flex flex-row items-center justify-center my-40">
					<Typography color="textPrimary" className="text-14 mb-20 mt-16 font-normal">
						Syncing...
					</Typography>
				</div>
			) : state === "LOADED" || state === "SYNC_FAILURE" ? (
				<>
					{!!data.axleConnection && !data.carrier_dot_number && data?.validateMCMethod === "ELD" ? (
						<Alert className="flex flex-row w-full mb-12" severity="error">
							Your ELD Connection doesn't provide DOT information, please continue the validation manually
						</Alert>
					) : null}

					{/* <Icon className="text-36 text-blue mr-6">sync</Icon> 
						<Typography color="textPrimary" className="flex-1 text-13 mb-14 mt-10 font-normal">
							What's your MC/DOT number?
						</Typography>
						*/}

					<SmarthopFormView
						key="send"
						data={{
							carrier_mc_number: carrierData.carrier_mc_number,
							carrier_dot_number: carrierData.carrier_dot_number,
							typeCompany: carrierData.typeCompany,
							parent: parent,
							carrierRepresentative: carrierData.carrierRepresentative,
						}}
						content={{
							form: { noErrorMessage: true },
							items: [
								...(!isDispatcher
									? [
											{
												type: "group",
												content: {
													items: [
														{
															key: "carrier_mc_number",
															type: "number",
															label: "MC #",
															translate: "MC_NUMBER",
														},
														{
															key: "carrier_dot_number",
															type: "number",
															label: "DOT #",
															translate: "DOT_NUMBER",
														},
													],
												},
											},
									  ]
									: []),
							],
						}}
						noInitValidation={true}
						trackChangedFields={["ALL"]}
						onChangeCommitted={(model) => {
							setMC(model.carrier_mc_number);
							setDOT(model.carrier_dot_number);
							setCarrierType(model.typeCompany);
							setCarrierRepresentative(model.carrierRepresentative);
							setCarrierData({
								...(carrierData ?? {}),
								carrier_mc_number: model.carrier_mc_number,
								carrier_dot_number: model.carrier_dot_number,
								typeCompany: model.typeCompany,
								parent: parent,
								carrierRepresentative: model.carrierRepresentative,
							});
						}}
					/>
					<Typography color="textPrimary" className="text-13 mb-14 mt-10 font-normal self-start">
						We need to validate your MC or DOT number in order to ensure you get access to load sources, including load
						boards and brokers.
					</Typography>
					{hasDispatchService && (
						<Typography color="textPrimary" className="text-13 mb-14 mt-10 font-semibold">
							If you are a dispatch service: please complete this process for one of your customers.
						</Typography>
					)}
					<div
						className={
							"flex flex-col w-full items-center justify-center mt-8 px-8 " + (isDispatcher ? "mt-10" : "mt-8")
						}
					>
						{/* <Button
							key="sync"
							color="secondary"
							variant="contained"
							className={"flex-1 w-full px-24"}
							onClick={() =>
								hasDispatchService ? createAccount() : !isDispatcher ? syncDOTInfo(carrierId) : continueManually()
							}
						>
							{state === "SYNC_FAILURE" ? "Retry" : "Continue"}
						</Button> */}
						{state === "SYNC_FAILURE" && (
							<>
								<div className="flex flex-row w-full items-center pb-6 pl-10 pr-20 justify-center border-t-1 mt-20">
									{/* <Icon className="text-36 text-red mr-10">sync_problem</Icon> */}
									<Typography className="flex-1 text-13 text-red mb-10 mt-16 font-normal">
										We were unable to automatically fetch your company information. Please make sure you provide valid
										DOT or/and MC number(s).
									</Typography>
								</div>
							</>
						)}
					</div>
				</>
			) : state === "LOADING" ? (
				<div className="flex flex-row items-center justify-center my-40">
					<Typography color="textPrimary" className="text-14 mb-20 mt-16 font-normal">
						Loading...
					</Typography>
				</div>
			) : null}
		</div>
	);
};

export default ValidateMCView;
