import { useEffect, useState, useMemo } from "react";
import { KeyValue, KeyValues, FormattedValue } from "./onboarding/settings";
import { CircularProgress, Icon, Typography } from "@material-ui/core";
import { getCostStructureView } from "app/services/costStructureServices";
import { getCarrierId } from "app/services/LoginService";
import { useSelector } from "react-redux";
import { createTooltip } from "app/main/utils/tableUtils";

const CostStructureView = ({ dataIds, classes = {}, variant = "default" }) => {
	const carrierId = useMemo(() => dataIds?.carrierId ?? getCarrierId(), [dataIds]);
	const [data, setData] = useState({});
	const [loading, setLoading] = useState(false);
	const costStructureRevision = useSelector(({ tools }) => tools.revision["costStructureRevision"]);
	const goalsCarrierRevision = useSelector(({ tools }) => tools.revision["goalsCarrierRevision"]);

	// load the data
	useEffect(() => {
		if (loading === false) {
			setLoading(true);
			const getCost = async () => {
				const res = await getCostStructureView(dataIds.id, carrierId);
				return res;
			};

			getCost().then((res) => {
				setData(res);
				setLoading(false);
			});
		}
		// eslint-disable-next-line
	}, [costStructureRevision, goalsCarrierRevision, dataIds, carrierId]);

	const getAddonValue = (field) => {
		const warning = data?.calculatedFields?.warnings?.[field];
		if (!warning) return null;

		return createTooltip(<Icon className="text-20 text-orange-700">warning</Icon>, warning);
	};

	if (loading) {
		return (
			<div
				className={
					"flex w-full flex-col items-center justify-center" + (variant === "truck_preview" ? " h-224 " : " h-512 ")
				}
			>
				<CircularProgress className="text-grey" />
			</div>
		);
	}

	return (
		<div
			className={`flex flex-col items-start min-w-300   ${classes?.root ?? "p-4 m-10"} ${
				variant !== "truck_preview"
					? " flex-col space-y-20 "
					: " flex-col md:flex-row space-y-20 md:space-y-0 space-x-0 md:space-x-40 "
			}`}
		>
			{variant !== "truck_preview" && (
				<div className="flex flex-col w-full">
					<div className="flex flex-row items-center content-center w-full space-x-4 -mt-6">
						<Typography component="div" className="text-18 font-medium">
							{data?.costStructure?.name_cost_structure}
						</Typography>
						{Object.keys(data?.calculatedFields?.warnings ?? {}).length > 0 && (
							<div className="mt-4">
								{createTooltip(<Icon className="text-orange-700 h-full">warning</Icon>, "Cost Structure has warnings")}
							</div>
						)}
					</div>
					<Typography className="text-13 text-grey -mt-6 mb-8">Summary and projections</Typography>
					<Typography className="text-15 text-primary-300 font-bold border-b-1 mb-6 pb-4 mt-8">
						Configuration
					</Typography>
					<KeyValue
						label="Fixed Costs per Month"
						value={data?.costStructure?.fixedMonthlyCosts}
						fieldType="currency"
						decimals={0}
					/>
					<KeyValue
						label="Maintenance Costs per Mile"
						value={data?.costStructure?.costPerMile}
						fieldType="currency"
						decimals={2}
						valueAddon={getAddonValue("costStructure.costPerMile")}
					/>
					<KeyValue label="Fees" value={data?.costStructure?.percentageCost} fieldType="percent" />
					<KeyValue label="Driving Days per Month" value={data?.costStructure?.period} />
					<KeyValue
						label="Average Miles per Day"
						value={data?.costStructure?.avgMilesPerDay}
						fieldType="miles"
						valueAddon={getAddonValue("costStructure.avgMilesPerDay")}
					/>
					<KeyValue
						label="Projected Miles per Month"
						value={data?.calculatedFields?.miles}
						fieldType="miles"
						decimals={0}
					/>
				</div>
			)}
			<div className="flex flex-col w-full">
				<Typography className="text-15 text-primary-300 font-bold border-b-1 mb-6 pb-4">Projections</Typography>
				<KeyValues
					label="Fixed Payments"
					value1={<FormattedValue value={data?.costStructure?.fixedMonthlyCosts} fieldType="currency" decimals={0} />}
					value2={
						<FormattedValue
							value={data?.calculatedFields?.fixedCostPerMile}
							fieldType="currency"
							decimals={2}
							suffix=" / mi"
						/>
					}
				/>
				<KeyValues
					label="Maintenance *"
					value1={<FormattedValue value={data?.calculatedFields?.costPerMile} fieldType="currency" decimals={0} />}
					value2={
						<FormattedValue value={data?.costStructure?.costPerMile} fieldType="currency" decimals={2} suffix=" / mi" />
					}
				/>
				<KeyValues
					label="Fuel Cost *"
					value1={<FormattedValue value={data?.calculatedFields?.fuel} fieldType="currency" decimals={0} />}
					value2={
						<FormattedValue
							value={data?.calculatedFields?.fuelCostPerMile}
							fieldType="currency"
							decimals={2}
							suffix=" / mi"
						/>
					}
				/>
				<KeyValues
					label="Driver Pay *"
					value1={<FormattedValue value={data?.calculatedFields?.driver} fieldType="currency" decimals={0} />}
					value2={
						<FormattedValue
							value={data?.calculatedFields?.driverCostPerMile}
							fieldType="currency"
							decimals={2}
							suffix=" / mi"
						/>
					}
				/>
				<Typography className="flex-1 text-end text-12 text-grey ml-1 -mt-2">
					* estimated using projected {data?.calculatedFields?.miles} monthly miles
				</Typography>
				<div className="text-16 text-grey-600 font-medium border-b-1 mb-6 mt-6"></div>
				<KeyValues
					label="All In Costs"
					value1={
						<FormattedValue
							value={data?.calculatedFields?.total}
							fieldType="currency"
							decimals={0}
							normalColor="text-red-600"
						/>
					}
					value2={
						<FormattedValue
							value={data?.calculatedFields?.totalCostPerMile}
							fieldType="currency"
							decimals={2}
							suffix=" / mi"
							normalColor="text-red-600"
						/>
					}
					valueAddon={getAddonValue("calculatedFields.totalCostPerMile")}
				/>
			</div>
			{data?.costStructure?.equipment !== "SPECIAL" && (
				<div className="flex flex-col w-full">
					<Typography className="text-15 text-primary-300 font-medium border-b-1 mb-6 pb-4">Expectations</Typography>
					<>
						<KeyValues
							label={`Default ${data?.costStructure?.equipment} Goal *`}
							value1={
								<FormattedValue
									value={data?.calculatedFields?.goalRevenue}
									fieldType="currency"
									decimals={0}
									normalColor="text-green-600"
								/>
							}
							value2={
								<FormattedValue
									value={data?.calculatedFields?.goalRPM}
									fieldType="currency"
									decimals={2}
									suffix=" / mi"
									normalColor="text-green-600"
								/>
							}
						/>
						<Typography className="flex-1 text-end text-12 text-grey ml-1 -mt-2">
							* RPM estimated using projected {data?.calculatedFields?.miles} monthly miles
						</Typography>
					</>

					<KeyValues
						label={`Market ${data?.costStructure?.equipment} Average *`}
						value1={
							<FormattedValue
								value={data?.calculatedFields?.revenue}
								fieldType="currency"
								decimals={0}
								normalColor="text-black-600"
							/>
						}
						value2={
							<FormattedValue
								value={data?.calculatedFields?.rpm}
								fieldType="currency"
								decimals={2}
								suffix=" / mi"
								normalColor="text-black-600"
							/>
						}
					/>
					<Typography className="flex-1 text-end text-12 text-grey ml-1 -mt-2">
						* estimated using {data?.costStructure?.equipment} market national average RPM $
						{data?.calculatedFields?.rpm}
					</Typography>
				</div>
			)}
		</div>
	);
};

export default CostStructureView;
